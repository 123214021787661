import React from 'react';
import {Link, NavLink} from 'react-router-dom';

export default function Navbar() {

    const projectDropDown = () => {
        let p = document.getElementById('project-dropdown');
        if (p.style.opacity == "0") {
            p.style.display = "block";
            p.style.opacity = "1";
        }
        else {
            p.style.display = "none";
            p.style.opacity = "0"; 
        }
    }


    return (
        <div>
            {/* <ul id="project-dropdown" className="dropdown-content">
                <li><NavLink to="/todos" onClick={projectDropDown}>Todo App</NavLink></li>
                <li><NavLink to="/weather-app" onClick={projectDropDown}>Weather App</NavLink></li>
            </ul> */}
            <nav className="blue darken-3">
            <div className="container nav-wrapper">
                    <a href="#!" className="brand-logo center">React Projects</a>
                    <ul className="MainMenu right hide-on-med-and-down">
                        <li><NavLink to="/">About</NavLink></li>
                        {/* <li>
                            <NavLink to="#!" onClick={projectDropDown}>
                                Projects
                            </NavLink>
                        </li> */}
                        {/* <li><NavLink to="/Blog">Blog</NavLink></li> */}
                        <li><NavLink to="/Contact">Contact</NavLink></li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}
