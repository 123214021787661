import React, { Component } from 'react';
import axios from 'axios';
import Header from '../components/layout/Header';

export default class Blog extends Component {

    state = {
        posts: []
    }

    componentDidMount() {
        axios.get("http://jsonplaceholder.typicode.com/posts?_limit=10")
        .then(res => {
            console.log(res)
            this.setState({
                posts: res.data
            })
        })
    }

    render() {
        const { posts } = this.state;
        const postList = posts.length ? (
            posts.map(post => {
                return (
                    <div className="post card" key={post.id}>
                        <div className="card-content">
                            <h3 className="card-title">{post.title}</h3>
                            <p>{post.body}</p>
                        </div>
                    </div>
                )
            })
        ): (<div className="center">No Posts Yet!</div>
        )
        return (
            <div>
                <Header title="Blog Post Data Loaded With Axios"/>
                <div className="container">{postList}</div>
            </div>
            
        )
    }
}
