import React, { Component } from 'react'
import Header from '../components/layout/Header';
import axios from 'axios'

/* 
SENDING EMAILS WITH REACT
https://www.youtube.com/watch?v=HZOXPta21PI

FORM HANDELING
https://www.youtube.com/watch?v=7Vo_VCcWupQ

https://blog.mailtrap.io/react-contact-form/
*/



export default class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email: '',
          phone: '',
          message: ''
        }
      }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);

        // Send HTTP request to the back end API. 

        // With JavaScript Fetch API
        /*
        fetch('http://localhost:3002/send',{
            method: "POST",
            body: JSON.stringify(this.state),
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            }).then(
                (response) => (response.json())
            ).then((response)=>{
            if (response.status === 'success'){
                alert("Message Sent."); 
                this.resetForm()
            }else if(response.status === 'fail'){
                alert("Message failed to send.")
            }
        })
        */
        
        // With Axios.  -------------------------------
        // Need to install package:     npm install axios 
        // Then import it in:           import axios from 'axios'
        axios({
            method: "POST", 
            url:"http://localhost:3002/send", 
            data:  this.state
          }).then((response)=>{
            if (response.data.status === 'success'){
              alert("Message Sent."); 
              this.resetForm()  // Reset form if success
            }else if(response.data.status === 'fail'){
              alert("Message failed to send.")
            }
        })
    }

    resetForm(){
        this.setState({name: '', email: '', phone: '', message: ''})
     }

    

    // Function that gets called on onChange event
    onChange = (e) => {
        this.setState({
            // Values from the form are passed to event then added to state.
            [e.target.name] : e.target.value // (Works with any input)
            });
        console.log(e.target.value)
    }

    

    render() {
        return (
            <div>
                <Header title="Contact Me"/>
                <div class="container row">
                    {/* Small Screens 12/12 col     Medium & Large Screens  6/12 col */}
                    <div className="col s12 m6 l6">
                        <h6 style={{fontSize: '2em', margin:0}}><strong>Contact Me</strong></h6>
                        <form method="POST" onSubmit={this.handleSubmit}>
                        
                            <div className="input-field">
                                <i className="material-icons prefix">account_circle</i> 
                                <input className="InputIcons" id="name_prefix" type="text" name="name" placeholder="Name*" onChange={this.onChange}/>
                            </div>
                        
                            <div className="input-field">
                                <i className="material-icons prefix">email</i> 
                                <input className="InputIcons" type="text" name="email" placeholder="Email*" onChange={this.onChange}/>
                            </div>
            
                            <div className="input-field">
                                <i className="material-icons prefix">phone</i> 
                                <input className="InputIcons" type="text" name="phone" placeholder="Phone"onChange={this.onChange}/>
                            </div>
                        
                        <textarea name="message" placeholder="Message" onChange={this.onChange}/>
                            <button className="btn waves-effect waves-light blue" type="submit" name="action">
                                Send
                                <i className="material-icons right ">send</i>
                            </button>
                        </form>
                    </div>
                    
                   
                    {/* Small Screens 12/12 col     Medium & Large Screens  6/12 col */}
                    <div className="col s12 m6 l6">
                    <h2 style={{fontSize: '2em', margin:0, marginBottom: 24}}><strong>React JavaScript Web Developer</strong></h2>
                        <div className="row">
                            <div className="col s6">
                                <img className="col s12" src="https://www.greenmarketing.ca/wp-content/uploads/2018/08/Nathan-Ergang-Digital-Marketing-Services.jpg" alt="Nathan Ergang"/>
                            </div>
                            <div className="col s6">
                                <b>Name:</b> Nathan Ergang <br/>
                                <b>Email:</b> Nate@GreenMarketing.ca<br/>
                                <b>Phone:</b> 1 213 814 3581<br/>
                                <b>From:</b> <h2 style={{fontSize:'1em', margin:0, display: 'inline'}}>Vancouver, BC Canada</h2><br/>
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </div>
        )
    }
}


