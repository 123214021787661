import React, { Component } from 'react';
import './App.css';


/*

Recipe App
https://www.youtube.com/watch?v=U9T6YkEDkMo

Multi Step Forms
https://www.youtube.com/watch?v=zT62eVxShsY

Quiz App
https://www.youtube.com/watch?v=aq-fCtg_gG4
https://www.youtube.com/watch?v=Iu44pB0gCp8
https://www.youtube.com/watch?v=zI27VJ1jxH8&list=PLraJmOvF9eeEHmQX1n1IFGgkJXpuWEwj8
https://www.youtube.com/watch?v=riDzcEQbX6k

Chat App
https://www.youtube.com/watch?v=jFNHerJqvFw

Text Generatator App
https://www.youtube.com/watch?v=yU5DYccb77A

Pokemon App
https://www.youtube.com/watch?v=o3ZUc7zH8BE

Budget Calculator
https://www.youtube.com/watch?v=f6HYLHrYpGs

Login & Register
https://www.youtube.com/watch?v=BNdIOnn-wik
https://www.youtube.com/watch?v=juUaJpMd2LE
https://www.youtube.com/watch?v=JiFCwo3iBuk
https://www.youtube.com/watch?v=XHPL-rX9m-Q

Stock Market Tracker
https://www.youtube.com/watch?v=onSKOD3RPo8

Blog Project
https://www.youtube.com/watch?v=P8q7CRSMJRA

Ecommerce
https://www.youtube.com/watch?v=023Psne_-_4&list=PLr4Zl5ERrphGhCyDiPPpZMbE68_fc9ey7

Sending Mail
https://www.youtube.com/watch?v=bo6sx5KUC6U

*/






// NAVIGATION
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import Navbar from './components/layout/NavBar';

// Import all your pages as well to app.js
import Home from './pages/Home';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
//import TodoApp from './projects/TodoApp';
import TodoAppAxios from './projects/TodoAppAxios';
import TodoApp from './projects/TodoAppLocalstorage';
import WeatherApp from './projects/WeatherApp';
import GalleryApp from './projects/GalleryApp';

class App extends Component {
    render() {
    return (
    <BrowserRouter>
      <div className="App">
          <Navbar />
          <Route exact path='/' component={Home} />
          <Route path='/todos' component={TodoApp} />
          <Route path='/todos-axios' component={TodoAppAxios} />
          <Route path='/blog' component={Blog} />
          <Route path='/contact' component={Contact} /> 
          <Route path='/weather-app' component={WeatherApp} />  
          <Route path='/Gallery' component={GalleryApp} />     
      </div>
    </BrowserRouter>
    );
  }
}

export default App;
