import React, { Component } from 'react'
import ImageCard from './Gallery/ImageCard'
import Header from '../components/layout/Header';
import './Gallery/styles.css';

/*


https://www.youtube.com/watch?v=GZ4d3HEn9zg

FIREBASE? https://www.youtube.com/watch?v=HHO2dT5pdpE

IMAGE SLIDER
https://www.youtube.com/watch?v=3ax9TW2c2bY


CREATING AN IMAGE GALLERY IN REACT -------
https://www.youtube.com/watch?v=FiGmAI5e91M

TAILWIND CSS FRAMEWORK  -------
www.smashingmagazine.com%2F2020%2F02%2Ftailwindcss-react-project%2F&v=FiGmAI5e91M&event=video_description&redir_token=GH-JYPk7F3u9bGQaAfRBjwC3JZh8MTU5MTMzMTQxMEAxNTkxMjQ1MDEw
Low level utility classes

Install dependenceies we need
npm i -D tailwindcss postcss-cli autoprefixer
-D Dev Dependency

Whats a vender prefix?
Can I use.com

Set up config files
npx tailwind init tailwind.js --full
touch postcss.config.js 
Lots of setup instructions for Tailwind
ends at 8:49

Step 1: Build out an image card jsx
Step 2: Pixabay Api Key

IDEA: 
Create my own pixel bay account with some images I dont mind giving away for free

State
Image = is an empty array
isLoading = true
search term

Fetch API 19min
Fetch the data from API and put it into your state


Pixabay API
DOCUMENTATION: https://pixabay.com/api/docs/ 
5,000 requests per hour.

https://pixabay.com/api/16882029-213e92833e75500ca317cab3f


fetch(``)
.then(res => res.json())
// .then(data => console.log(data))
.then(data => {
    // setstate image array to data.hits
    // set state is loading to false
}
.catch(err => console.log(err));


Create a component called card. Pass in data via props
<ImageCard />
<img src={image.webformatURL}
{image.user}
{image.views}
{image.likes}

Tags is a comma separated value

APP.JS
Loop through all the images from the API and create a card for them
Create a grid

{images.map(image => (
    <ImageCard key={image.id} image={image} />
))}

*/





export default class GalleryApp extends Component {

    api = {
        key: "16882029-213e92833e75500ca317cab3f",
        base: "https://pixabay.com/api/?key="
    }

    state = {
        images: [],
        isLoading: true,
        searchTerm: 'saigon',
        //user:'greenmarketing-16882029'
        user:'stux flowers' 
    }

    // UNSPLASH  API   https://unsplash.com/oauth/applications/142035
    /*

    URL
    https://api.unsplash.com/
    https://api.unsplash.com/photos/?client_id=0LVUqw1HoT_NpxwFDZYxDCih9EC-UpC1-xZhXJfj4qE


    Access Key
        0LVUqw1HoT_NpxwFDZYxDCih9EC-UpC1-xZhXJfj4qE
    Secret key
        ccc0B9brJuqamntQbSErn51NMsmi8vbXtkIGorNLMEI
    */




    // When component is loaded pull data from pixabay.com
    componentDidMount() {
        //fetch(`https://pixabay.com/api/?key=16882029-213e92833e75500ca317cab3f&q=space`)
        fetch(`${this.api.base}${this.api.key}&q=${this.state.searchTerm}`)       // Just search term
        // fetch(`${this.api.base}?username=${this.state.user}&key=${this.api.key}`)   // With username
        // fetch(`https://pixabay.com/api/?user=mjweaver01&key=16882029-213e92833e75500ca317cab3f`)
        fetch(`https://api.unsplash.com/users/greenmarketing/photos/?client_id=0LVUqw1HoT_NpxwFDZYxDCih9EC-UpC1-xZhXJfj4qE`)
        .then(res => res.json())
        .then(data => {
            console.log(data)
            // setstate image array to data.hits
            // set state is loading to false
            this.setState({
                  images: data,
                  isLoading: false
            }, ()=> console.log(this.state.images));   
        })
        .catch(err => console.log(err));   
    }


     // Stores seach results in the state.
     onChange = (e) => this.setState({
        searchTerm: e.target.value
    });

    search = (e) => {
        // If enter is pressed
        if (e.key === "Enter") {
            // Fetch API
            fetch(`${this.api.base}${this.api.key}&q=${this.state.searchTerm}`)  
            // Create Promise
            .then(res => res.json())
            .then(data => {
                this.setState({
                    images: data.hits,
                    isLoading: false
                });
            }).catch(function() {
                    document.getElementById('noData').innerText = 'Sorry that place must have skipped our records';
                    
            });
        }
    }


    openPopup = (e) => {
        alert("Open Popup Image: " + e.target.id)
    }



    render() {
        return (
            <div id="Gallery-App">
                <Header title="Dynamic Gallery"/>
                <div className="container">

                    <div className="search-box container">
                        <div className="input-field">
                        <i className="material-icons prefix">search</i> 
                            <input 
                                type="text" 
                                className="search-bar"
                                placeholder="Search for your city..."
                                onChange={this.onChange}
                                value={this.state.searchTerm}
                                onKeyPress={this.search}/>
                            </div>
                    </div>


                    <div className="row">
                
                    {/* Loop through all the images and create a card to hold them  */}
                    {this.state.images.map(image => (
                        <ImageCard key={image.id} image={image} openPopup={this.openPopup}/>
                    ))
                    }

                    </div>
                </div>
            </div>
        )
    }
}
