import React from 'react'
import {Link} from 'react-router-dom';
import SmartLink from './SmartLink'


// Import Images
import reactI from '../../images/50px/React_Icon.jpg'
import cssI from '../../images/50px/Css-Icon_2.jpg'
import jQueryI from '../../images/50px/jQuery_Icon.jpg'
import photoshopI from '../../images/50px/Photoshop_Icon.jpg'
import wordpressI from '../../images/50px/Wordpress-Icon.jpg'
import illustratorI from '../../images/50px/illustrator-Icon.jpg'
import aftereffectI from '../../images/50px/After-Effects-Icon.jpg'
import APII from '../../images/50px/API-Icon.jpg'
import AxoisI from '../../images/50px/Axois-Icon.jpg'
import firebaseI from '../../images/50px/Fire-Base-Icon.jpg'
import htmlI from '../../images/50px/Html-Icon.jpg'
import javascriptI from '../../images/50px/JavaScript-Icon.jpg'
import mongodbI from '../../images/50px/MongoDB-Icon.jpg'
import phpI from '../../images/50px/Php-Icon.jpg'
import sqlI from '../../images/50px/SQL-Icon.jpg'
import woocommerceI from '../../images/50px/Woo-Commerce-Icon.jpg'


export default function ProjectBox(props) {

    let projectIcons = () => {
        
        return ( 
            <div className="icon-container">
            { props.react && <img className="tech-icon" src={reactI}/> }
            { props.css && <img className="tech-icon" src={cssI}/> }
            { props.jQuery && <img className="tech-icon" src={jQueryI}/> }
            { props.photoshop && <img className="tech-icon" src={photoshopI}/> }
            { props.wordpress && <img className="tech-icon" src={wordpressI}/> }
            { props.illustrator && <img className="tech-icon" src={illustratorI}/> }
            { props.aftereffect && <img className="tech-icon" src={aftereffectI}/> }
            { props.api && <img className="tech-icon" src={APII}/> }
            { props.axois && <img className="tech-icon" src={AxoisI}/> }
            { props.firebase && <img className="tech-icon" src={firebaseI}/> }
            { props.html && <img className="tech-icon" src={htmlI}/> }
            { props.javascript && <img className="tech-icon" src={javascriptI}/> }
            { props.php && <img className="tech-icon" src={phpI}/> }
            { props.sql && <img className="tech-icon" src={sqlI}/> }
            { props.woocommerce && <img className="tech-icon" src={woocommerceI}/> }
            
        </div>
        )
        
    }
    // https://github.com/ReactTraining/react-router/issues/1147
    return (
        <SmartLink to={props.link}>
            <div className="project-box">
                <h2>{props.title}</h2>
                <img src={props.img} className="panel-Image"/>
                <p>{props.children}</p>
                {projectIcons()}
            </div>
        </SmartLink>
    )
}
