import React from 'react'

// With a functional component a return acts as the render method
export default function Header(props) {

    const headerStyle = () => {
        return {
            padding:'10px',
            background: '#152750',
            color:'#fff',
            textAlign: 'center',
            marginBottom: props.margin || '40px',
            backgroundImage: `url(${props.background})` || 'none',
            height: props.height || 'auto',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }
    }

    return (
        <header style={headerStyle()}>
            <h1 style={headlineStyle}>{props.title}</h1>
        </header>
    )
}

const headlineStyle = {
    fontSize:'25px',
    margin:0,
    padding:10
}