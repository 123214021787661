import React, { Component } from 'react';
import TodoItem from './TodoItem';
import PropTypes from 'prop-types';

class Todos extends Component {

   
  render() {

    let results = () => {
              
              if (this.props.filtered.length) {
                return(
                  this.props.filtered.map((todo) => (
                      <TodoItem 
                      key={todo.id} 
                      todo={todo} 
                      toggleComplete={this.props.toggleComplete}
                      delTodo={this.props.delTodo}
                      editTodo={this.props.editTodo}
                      starTodo={this.props.starTodo}/>    
                  ))
              )
              }
              else if (this.props.todos.length) {
                return(
                    this.props.todos.map((todo) => (
                        <TodoItem 
                        key={todo.id} 
                        todo={todo} 
                        toggleComplete={this.props.toggleComplete}
                        delTodo={this.props.delTodo}
                        editTodo={this.props.editTodo}
                        starTodo={this.props.starTodo}/>    
                    ))
                )
              }
              else {  return(  <p className="center">Nothing to do. Let’s make a new todo list!</p> ) }
    }
    
    return (

      <div>{results()} </div> 

    )
  }
}

// Prop Types: Best practice
Todos.propTypes = {
  todos: PropTypes.array.isRequired
}

export default Todos;