import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class AddTodo extends Component {
    
    
    //  REACT DATE AND TIME PICKER COMPONENT    https://reactdatepicker.com/
    // https://www.npmjs.com/package/react-datepicker
    // https://stackoverflow.com/questions/50430968/converting-string-date-in-react-javascript
    
   
   
   
    // https://www.pluralsight.com/guides/prop-changes-in-react-component

    // Checks to see if component did update
    componentDidUpdate(prevProps) {
        // Compaire previous props on edit data with new
        // If it has changed the user is editing a todo
        if (prevProps.editData[0] !== this.props.editData[0]) {

            // TESTING
            // console.log("------- PROPS: -------")
            // console.log("ID: " + this.props.editData[0].id)
            // console.log("TITLE: " + this.props.editData[0].title)
            // console.log("NOTES: " + this.props.editData[0].notes)
            // console.log("TIME: " + this.props.editData[0].timetodo)

            // Update the state with data from todo being editing
            this.setState({
                id: this.props.editData[0].id,
                title: this.props.editData[0].title,
                //timetodo: this.props.editData[0].timetodo,
                notes: this.props.editData[0].notes || ''       // If undefined make empty
            })

        }
   }  


    
    // Component State.
    state = {
        id: '',
        title: '',
        timetodo: '',
        notes: ''
    }
    
    // Function that gets called on on change event
    onChange = (e) => {
        this.setState({
            // Values from the form are passed to event then added to state.
            // Input name : Input value
            // title : e.target.value    (Only works with one input)
            [e.target.name] : e.target.value   // (Works with any input)
            
        });
    }


    handleDateChange = date => {
        this.setState({
            timetodo: date
        });
        //console.log(typeof(stringDate))
    };

    formatDate(string){
        if(string) {
            var options = { year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' };
            // let computerDate = string.toISOString()
            let computerDate = Math.round((string / 1000));
            let readableDate = new Date(string).toLocaleDateString([],options)
            return [readableDate,computerDate];
        }
    }

    // Function Gets Called ON SUBMIT
    onSubmit = (e) => {
        // Stops form submiting to external script
        e.preventDefault(); 
        // If todo is not empty add it to the list
        if (e.target.title.value) { 
            let stringDate =  this.formatDate(this.state.timetodo) // Convert to a string 
            
            // If Id exists user hit edit button so just update the existing todo
            if (this.state.id) {
                // Calls a function in parent and sends data up the chain
                this.props.updateTodo(this.state.id,this.state.title,stringDate,this.state.notes);
            }
            // If no id exists add a new todo
            else {
                // Calls a function in parent and sends data up the chain
                this.props.addTodo(this.state.title,stringDate,this.state.notes);
            }


            // Empties state
           this.setState({title: '', notes:'', id:'', timetodo:''})
        }
    }


    // Function Gets Called ON SUBMIT
    cancelEdit = (e) => {
            // Empties state
           this.setState({title: '', notes:'', id:'', timetodo:''})
    }


    
    render() {

        const btnStyle = {
            height: 'auto',
            lineheight: '26px',
            flex:2,
            width:50,
        }
        const TodoInputStyle = {
            flex:10, 
            width:'auto'
        }

        const timeInputStyle = {
            flex:3, 
            width:'auto'
        }

        const cancelBTN = this.state.id ? (<a onClick={this.cancelEdit} className="cancelBTN">Cancel Edit</a>) : ('')
        const addOrEdit = this.state.id ? ("edit") : ("add")

        return (
            <form id="todoForm" className="todoForm" onSubmit={this.onSubmit} style={{marginBottom:15}}>
            <div style={{display:'flex'}}>
                <div className="input-field"  style={TodoInputStyle}>
                        <i className="material-icons prefix">assignment</i>
                        <input 
                            type="text" 
                            name="title" 
                            placeholder="Add Todo"
                            value={this.state.title}
                            onChange={this.onChange}
                            className='TodoInput InputIcons'
                        />
                </div>
                {/* <div className="input-field" style={timeInputStyle}> 
                        <i className="material-icons prefix">alarm</i>
                        <input 
                            type="text" 
                            name="timetodo" 
                            placeholder="Time"
                            value={this.state.timetodo}
                            onChange={this.onChange}
                            onClick={this.selectDateTime}
                            className='TimeInput InputIcons'
                        />
                </div> */}
                <div className="input-field" style={timeInputStyle}> 
                    <label htmlFor="date"><i className="material-icons prefix">alarm</i></label>
                    <DatePicker
                        selected={this.state.timetodo}
                        onChange={this.handleDateChange}
                        //onChange={date => this.handleDateChange(date)}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        className='TimeInput InputIcons'
                        id="date"
                        />
                </div>
                <div className="input-field">
                        <button className="btn blue AddTodoBtn" type="submit" name="action" style={btnStyle}>
                            <i className="material-icons">{addOrEdit}</i>
                        </button>
                </div>
            </div>
            <textarea name="notes" className="notes" placeholder="Notes" value={this.state.notes} onChange={this.onChange}/>
            {cancelBTN}
        </form>
        )
    }
}

