import React, { Component, PropTypes } from 'react';
import {Link} from 'react-router-dom';
import isExternal from 'is-url-external';

// npm install is-url-external
// npm install @types is-url-external
// https://www.npmjs.com/package/is-url-external
// import isExternal from "is-url-external";

//https://stackoverflow.com/questions/41292559/could-not-find-a-declaration-file-for-module-module-name-path-to-module-nam
// const isExternal = require('is-url-external');

// const propTypes = {
//     to: PropTypes.string.isRequired,
//   };

export default class SmartLink extends Component {
    render() {
        return( isExternal(this.props.to) ?
            <a
                href={this.props.to}
                {...this.props}
            />
            :
            <Link {...this.props} />
        )
    }
}

// SmartLink.propTypes = propTypes;