import React, { Component } from 'react'
// import PropTypes from 'prop-types';

export class TodoItem extends Component {
    
    itemStyle = () => {
        return {
        padding:'10px',
        margin: '2px',
        // Conditional styling turnary operator --------------------- 
        color: this.props.todo.completed ? 'lightgray' : '#000000', 
        backgroundColor: this.props.todo.completed ? '#ffffff': 
            this.props.todo.stared ? '#ffffa7':'#efefef'      
        }
    }

    todoDataStyle = () => {
        return {
            textDecoration: this.props.todo.completed ? 'line-through' : 'none'
        }
    }

    completeDisable = () => {
        // Hide notes once completed
        return {display: this.props.todo.completed ? 'none' : 'block',}
    }

    render() {
        // Destruturing - Pulls out id and title
        // Less typing / short varable names instad of this.props.todo.title
        const {id, title, timetodo, notes} = this.props.todo;
        let timeIcon
        let timeCondition = Array.isArray(this.props.todo.timetodo)
        // If time value exists
        if (timetodo)   {
            // If time value is array
            if (timeCondition) {
                // Show icon if first item is not empty
                if (timetodo[0] != null) {
                    timeIcon = <span className="cfm"><i className="material-icons prefix tiny">alarm</i>   {this.props.todo.timetodo[0]} </span>
                }
            }
            // If not array LEGACY
            else {
                timeIcon = <span className="cfm"><i className="material-icons prefix tiny">alarm</i>   {this.props.todo.timetodo} </span>
            }
        }
        else {
            timeIcon = ""     
        }

        return (
            <div className="todoItem cf" style={this.itemStyle()}>
                <div className="todoItemData" style={this.todoDataStyle()}>
                    <input type="checkbox" defaultChecked={this.props.todo.completed} onChange= {this.props.toggleComplete.bind(this, id)}  /> 
                    <i className="material-icons prefix tiny">assignment</i> <b className="todoTitle">{title} </b>
                    {timeIcon}
                <p className="todoNotes" style={this.completeDisable()}>{notes}</p>
                </div>
                <div className="todoItemUI">
                    <button onClick={this.props.starTodo.bind(this, id)} style={this.completeDisable()} className="yellow-btn btnStyle">
                        <i className="material-icons prefix">star</i>
                    </button>
                    <a href="#todoForm">
                    <button onClick={this.props.editTodo.bind(this, id)} style={this.completeDisable()} className="green-btn btnStyle">
                        <i className="material-icons prefix">edit</i>
                    </button>
                    </a>
                    <button onClick={this.props.delTodo.bind(this, id)} className="red-btn btnStyle">
                        <i className="material-icons prefix">delete_forever</i>
                    </button> 
                </div>

            </div>
        )
    }
}

// Prop Types: Best practice
// TodoItem.propTypes = {
//     todo: PropTypes.object.isRequired
//   }




export default TodoItem


// Calls an arrow function todo conditional styling
        // Checkbox with event that points to an arrow function in App.js
        // Pass in ID so we know which one to mark as complete
        // {' '} Adds a space