import React, { Component } from 'react';
import Todos from './TodoList/Todos';
import AddToDo from './TodoList/AddTodo';
import TodoMenu from './TodoList/TodoMenu';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
// Import Random ID Generator
import { uuid } from 'uuidv4';
import './TodoList/style.css';
import headerImage from '../images/Task-Master-BG.jpg';


/*
https://www.robinwieruch.de/local-storage-react
https://programmingwithmosh.com/react/localstorage-react/
*/



 /* 
      SEARCH FEATURE ------------------------------------------------------------------

      https://dev.to/iam_timsmith/lets-build-a-search-bar-in-react-120j
      1. Create an array of our filtered list.
      - If the input bar is empty, it should display all items in the list.
      - If there is text in the search bar, it should only show items that 
      contain that text.
      
      2. Add state to our List component and give it an array called filtered.

          constructor(props) {
            super(props);
            this.state = {
                filtered: []
            }
          }

      3. Make sure the data is being put there.
      We need to pass data into our filtered state every time the List component 
      gets re-rendered. To do this, we will use a few lifecycle methods.
      The big deal is that now we have a list we can manipulate without altering the original list.
     
      Add these lifecycle methods to our List component
      componentDidMount 
      componentDidReceiveProps

      componentDidMount() {
        this.setState({
          filtered: this.props.items
        });
      }

      componentWillReceiveProps(nextProps) {
        this.setState({
          filtered: nextProps.items
        });
      }

      We also need to add an if statement so that the .filter() function only runs 
      if the input isn't empty. So if the search bar is not empty, we want to run the
      .filter() function

      


      https://medium.com/@reneecruz/search-bar-in-react-js-in-six-simple-steps-4849118b2134


      - We need to first pass the serch query data here
      https://www.w3schools.com/jsref/jsref_includes_array.asp
      
            let listItems = document.getElementsByClassName("todoTitle")
            for (let i=0; i < listItems.length; i++){
                console.log(listItems[i].innerText)
            }
      
_______________________________OTHER PAGES______________________________

MY COMMITMENTS ---------------------------------------------------------
What do I need to implement this year to grow myself personally & professionally
1.
2.
3.
Add new
Add tags


SMART GOALS ------------------------------------------------------------
S = Specific
M = Measurable
A = Attainable
R = Relevant
T = Timely

----- Questions:
What do you want to accomplish? (Be specific)
How can you measure your success to know if you hit your goal?
What would you consider benchmarks in hitting your goal?
What is your plan? How are you going to strategically hit your goal?
What does this goal mean to me? Will it better my quality of life? Make me more money? What is my "Why" for wanting to accomplish this goal?
What is my timeline for accomplishing this goal? How will I know when the goal is accomplished?
Who other than yourself will hold you accountable to your goal? (Accountability Partner)
What are you going todo if the goal is not accomplished? Think next steps to taking the goal to completion
How do I want to feel when the goal is accomplished?
What new positive things will come into my life when I accomplish this goal?

3 Months ____________________
6 Months ____________________
12 Months ___________________

HIRING PLAN WORKSHEET ------------------------------------------------
Job Role    Urgency    Timeline for hiring


Task Time  ------------------------------------------------

Task name. Start timer. End timer
Description








*/

export default class TodoAppLocalstorage extends Component {
    
    
    state = {
        todos: [    ],
        editData: '',
        lists: ['Master List'],
        currentList: 0,
        serchQuery: '',
        filtered: []
    }

    getSearch = (query) => {
        // Set search state in parent component and console log state
        this.setState(  {serchQuery: query}, ()=> {   
            // After it sets state
            let currentList = [];
            let newList = [];
            // If the search bar isn't empty
            if (this.state.serchQuery !== "") {
                      currentList = this.state.todos;
                      // Use .filter() to determine which items should be displayed
                      // based on the search terms
                      newList = currentList.filter(item => {
                          // change current list & query to lowercase
                          const lc = item.title.toLowerCase();
                          // console.log(lc)
                          const filter = query.toLowerCase();
                          // check to see if the current list item includes the search term 
                          return lc.includes(filter);
                      });
            } else {
                      // If the search bar is empty, set newList to original task list
                      newList = this.state.todos; 
            } 
            // Set the filtered state based on what our rules added to newList
            //this.setState(  {filtered: newList}  , ()=> {console.log(this.state)});
            this.setState(  {filtered: newList}  );
        });  
      }

    // Pulls todos from local storage based on current list selection
    getTodosFromLocalStorage = () => {
        let values = [],
            keys = Object.keys(localStorage),
            keylist = [],
            k = 0;
            //console.log(keys)
        while (k < keys.length ) {
                keylist = (keys[k].split(","))  // Split off list number
                //console.log(keylist)
                //console.log(k)
                if (keylist[0] == this.state.currentList) {
                    values.push( JSON.parse(localStorage.getItem( keys[k])) );
                }
                k++
        }
        return values;
    }

    loadCurrentList = () => {
        let todoslocal = this.getTodosFromLocalStorage();
        let lists = [];
        
        if (localStorage.getItem("TM_Lists") !== null) {
          lists = JSON.parse(localStorage.getItem("TM_Lists"))
          this.setState({ todos: todoslocal, lists });   
        } 
        else {
          this.setState({ todos: todoslocal });  
        } 
    }

    // Load local storage into state
    componentDidMount() {
      this.loadCurrentList()
   }

  

  

  //---------------------------------------------------------------------------   Add Todo
  addTodo = (title,timetodo, notes) => {
    console.log("Adding Todo" + title)
    // Set up new Todo Item
    const newTodo = {
      // Generate a random ID
      id: uuid(),
      // title:title,   When key and title are the same just put one
      title,
      notes,
      completed:false,
      timetodo: timetodo
    }
    this.setState(  {todos: [...this.state.todos, newTodo]}   );
    
    // Add to local storage
    let storageKey = [this.state.currentList,newTodo.id];
    localStorage.setItem(storageKey, JSON.stringify(newTodo));
  }



  //--------------------------------------------------------------------------------------------- Update Todo
  updateTodo = (id, title, timetodo, notes) => {
    // console.log("ID: " + id + "TITLE:" + title + "TIME:" + timetodo + "NOTES:" + notes)
    
    // Remove item from local storage
    let storageKey = [this.state.currentList,id];
    localStorage.removeItem(storageKey);


    // Set up new Todo Item
    const newTodo = {
      // Generate a random ID
      id: uuid(),
      // title:title,   When key and title are the same just put one
      title,
      notes,
      completed:false,
      timetodo: timetodo
    }
    
    // Add to local storage
    storageKey = [this.state.currentList,newTodo.id];
    localStorage.setItem(storageKey, JSON.stringify(newTodo));


    
    // Create a copy of todos and delete old todo
    let todos = [...this.state.todos.filter(todo => todo.id !== id)]
    // Add new todo to the copy
    todos = [...todos, newTodo]
    // You can only set state once
    this.setState(
      {todos}
    );

  }





  //-------------------------------------------------------------------------------- Delete Todo
  delTodo = (id) => {
    //console.log("Delete Item:" + id)
    // Remove item from state by coping everything thats in there except removed item
    // Spread Operator
    this.setState({
      todos: [...this.state.todos.filter(todo => todo.id !== id)]
    });

    // Remove item from local storage
    let storageKey = [this.state.currentList,id];
    localStorage.removeItem(storageKey);
  }





  // https://medium.com/@ruthmpardee/passing-data-between-react-components-103ad82ebd17
  // I need to pass state from a specific todo to the form in the add todo component.
  editTodo = (id) => {
    //console.log (id)
    this.setState({
      editData: this.state.todos.map(todo=>{
        if (todo.id === id) { // Only return value for matching id
          return {id: todo.id, title:todo.title, notes:todo.notes, timetodo:todo.timetodo};
        }
      }).filter((el)=> {  // Filter out empty values
        return el != null;
      })
    });
  }

  

  starTodo = (id) => {
    //console.log("Star: " + id)
    this.setState({ 
      todos: this.state.todos.map(todo => {
      // Change State (Toggle)
      if (todo.id === id) {
        if (todo.stared) {
          todo.stared = !todo.stared
        }
        else {todo.stared = true}
        // Update local storage
        let storageKey = [this.state.currentList,id];
        localStorage.setItem(storageKey, JSON.stringify(todo));
      }
      return todo;
    }) });  
  }


  // We have to add this where the state is and pass it though with props to the sub components
  toggleComplete = (id) => {
    //console.log("Toggle Complete: " + id)
    // Loop through
    this.setState({ todos: this.state.todos.map(todo => {
      // Change State (Toggle)
      if (todo.id === id) {
        todo.completed = !todo.completed
        todo.stared = false
        let storageKey = [this.state.currentList,todo.id];
        localStorage.setItem(storageKey, JSON.stringify(todo));
        //console.log(storageKey + "JSON.stringify(todo)")
      }
      return todo;
    }) });  
  }



  buttonCommands = (id) => {
      const {todos} = this.state
      // const todos = [...this.state.todos];
      let orderedList = todos  // Create a copy
      //console.log(orderedList)

      // https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
      if (id == "byalphabetically") { /// -------------------------------------------------------------------  Sort todos alphabetically
              orderedList.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1)
              //console.log(orderedList)
              this.setState({ todos: orderedList })
      }
      else if (id == "bypriority") {  /// -------------------------------------------------------------------  Sort todos by priority
              
              //orderedList.sort((a, b) =>  (a.stared > b.stared) ? 1 : (a.completed > b.completed) ? 1 : -1)
              //orderedList.sort((a, b) => (a.completed > b.completed ) ? 1 : (a.stared > b.stared) ? 1 : -1)
              //console.log(orderedList)
              //this.setState({ todos: orderedList })

              // If it doesnt have stared propery give it a default one
              this.setState({ 
                todos: orderedList.map(todo => {
                    if (todo.stared == undefined) {
                      todo.stared = false
                    }
                    return todo
                })  
                }, ()=> {
                  let priorityOrderedList = this.state.todos
                  priorityOrderedList.sort((a, b) => (a.stared < b.stared) ? 1 : (a.completed > b.completed) ? 1 : -1)
                  this.setState({ todos: priorityOrderedList })
                })



      }
      else if (id == "byDueDate") {  /// -------------------------------------------------------------------  Sort todos by due date
        
        // If it doesnt have a date give it 0 as a date
        this.setState({ 
            todos: orderedList.map(todo => {
                if (todo.timetodo == undefined) {
                  todo.timetodo = [null,Infinity]
                }
                return todo
            })  
        }, ()=> {
          let dateOrderedList = this.state.todos
          // dateOrderedList.sort((a, b) => (a.timetodo[1] < b.timetodo[1]) ? 1 : -1)
          dateOrderedList.sort((a, b) => (a.completed > b.completed) ? 1 : (a.timetodo[1] > b.timetodo[1]) ? 1 : -1)
          this.setState({ todos: dateOrderedList })
          console.log(this.state)
        })
      } 
      else if (id == "download") {  /// -------------------------------------------------------------------  Save Data to JSON file
              this.saveDataJSON()
      }
      
  }


  editName = (newName) => {
    // https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
    // Make a copy
    let lists = [...this.state.lists];
    lists[this.state.currentList] = newName;
    this.setState({lists });  //, ()=> console.log(this.state)
    // Save to local storage
    localStorage.setItem("TM_Lists", JSON.stringify(lists));    
  }


  setNewList = (listname, nextlist) => {
    this.setState({currentList: nextlist}, ()=> {
      this.editName(listname)
      this.loadCurrentList()
    });
  }


    render() {
        return (
            <div>
                <Header margin="0" background={headerImage} height="200px"/>
                <TodoMenu buttonCommands={this.buttonCommands} currentList={this.state.currentList} lists={this.state.lists} getSearch={this.getSearch} editName={this.editName} setNewList={this.setNewList}/>
                <div className="container">
                  <AddToDo addTodo={this.addTodo} updateTodo={this.updateTodo} editData={this.state.editData}/>
                  <Todos 
                  todos={this.state.todos} 
                  filtered={this.state.filtered} 
                  toggleComplete={this.toggleComplete} 
                  delTodo={this.delTodo}
                  editTodo={this.editTodo}
                  starTodo={this.starTodo}/>
                </div>
                <br/>
                <br/>
                <Footer>Todo List App By - <a href="//www.greenmarketing.ca">GreenMarketing.ca © 2020</a></Footer>
            </div>
        )
    }
}
