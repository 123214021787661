import React, { Component } from 'react'
import CsvDownload from 'react-json-to-csv'



export default class todoMenu extends Component {

    state = {
        currentList: this.props.currentList,
        listName: this.props.lists,
        activeInput: 'editName',
        serchQuery: '',
        newListName: ''
    }

    // Checks to see if component did update
    componentDidUpdate(prevProps) {
        // New List
        if (prevProps.currentList !== this.props.currentList) {
            this.setState({currentList: this.props.currentList}, ()=> { })
        }
        if (prevProps.lists !== this.props.lists) {
            this.setState({listName: this.props.lists[this.state.currentList]}, ()=> { 
                    // console.log("----------  To Do Menu State -------------")
                    // console.log(this.state)
                })
        }
    }

    sendCommand = (e) => {
        // Stop events from being triggered on child
        // CSS .todoMenu button i{pointer-events: none;}
        // console.log(e.target.id);

        // Sends command to parent class
        this.props.buttonCommands(e.target.id);
    }

    onChange = (e) => {
        this.setState({
            // Values from the form are passed to event then added to state.
            // title : e.target.value    (Only works with one input)
            // Input name : Input value  (Works with any/multiple input)
            [e.target.name] : e.target.value   // (Works with any input)
        });
    }

    onEditName = (e) => {
        this.setState({     [e.target.name] : e.target.value     }, ()=> {
            this.props.editName(this.state.listName);
        });
    }

    

    onSubmitNewList = (e) => {   
        e.preventDefault();  
        this.props.setNewList(this.state.newListName,(this.props.lists.length));
        // Find out how many lists there are and add a new one to the end
        
        // Resets form state
        this.resetState()
    }

    
    resetState = () => {
        this.setState({
            activeInput: 'editName',
            serchQuery: '',
            newListName: ''
        })
    }


    onSearch = (e) => {
        // Store in state and send it up the chain to parent with a call back function
        this.setState({   [e.target.name] : e.target.value   }, ()=> {this.props.getSearch(this.state.serchQuery)});
    }

    // test = () => {
    //     console.log(this.state)
    // }

    // Console.log() exec before the state finish to update. Because setState() is an asynchronous function
    // this.setState({activeInput: "search"}, ()=> console.log(this.state.activeInput))
    // Makes it execulte after
    inputCommand = (e) => {
        let id = e.target.id;
        if (id == "search") {
            this.setState({activeInput: "search"}, ()=> console.log(this.state.activeInput))
          }
          else if (id == "editName") {
            this.setState({activeInput: "editName"}, ()=> {
                console.log(this.state.activeInput)
                //console.log(this.state.listName)
            })
            
          }
          else if (id == "newList") {
            this.setState({activeInput: "newList"}, ()=> console.log(this.state.activeInput))
          }
          else if (id == "switchList") {
            this.setState({activeInput: "switchList"}, ()=> console.log(this.state.activeInput))
          }
    }

    inputUpdate = () => {
        if (this.state.activeInput == 'search') {

            return (
            <div className="input-field">
                <i className="material-icons prefix">search</i>
                <input onChange={this.onSearch} type="text" className="todo_listName" value={this.state.serchQuery} name="serchQuery" placeholder="Search todos"></input>
                {/* <button id="submitNewList"><i className="material-icons">done</i></button> */}
            </div>
            )
        }
        if (this.state.activeInput == 'editName') {
            return (
            <div className="input-field">
                <i className="material-icons prefix">edit</i>
                <input onChange={this.onEditName} type="text" className="todo_listName" value={this.state.listName} name="listName"></input>
                <button id="submitNewList"><i className="material-icons">done</i></button>
            </div>
            )
        }
        if (this.state.activeInput == 'newList') {
            return (
            <div className="input-field">
                <form onSubmit={this.onSubmitNewList}>
                    <i className="material-icons prefix">playlist_add</i>
                    <input onChange={this.onChange} type="text" className="todo_listName" value={this.state.newListName} name="newListName" placeholder="Enter Name Of New List"></input>
                    <button id="submitNewList"><i className="material-icons">done</i></button>
                </form>
            </div>
            )
        }
        if (this.state.activeInput == 'switchList') {
            return (
            <div className="input-field">
                <i className="material-icons prefix">autorenew</i>
                <select onChange={this.onChangeList} name="listName"> 
                    <option value="">Select a project</option>
                    {this.allTodoLists()}
                </select>
            </div>
            )
        }
    }


    // Lists out all the different todo lists in the selection box
    allTodoLists = () => {
        let index = 1
        return (
        this.props.lists.map((list) => (   
                <option key={index} value={index}>{index++} {list}</option>  
            ))
        )
    }

    onChangeList = (e) => {
        // Get list Id  //console.log(e.target.value-1)
        let id = (e.target.value - 1);
        console.log(id)
        // Get List Name  console.log(listName);
        if (id != -1) {  
            let listName = this.props.lists[id]  
            // Send props up chain
            this.props.setNewList(listName,id);
        }
    }
    
    //------------------------------------------------------------------------  Save all data from local storage
   getAllDataFromLocalStorage = () => {
    let values = [],
    keys = Object.keys(localStorage),
    keylist = [],
    k = 0,
    keyListItem = []; 
    while (k < keys.length ) {

            // Split off list number
            keylist = (keys[k].split(","))  
            // Creates an array for each list item:   List ID   |   List Name    |     List Item Data
            keyListItem = [     keylist[0],this.props.lists[keylist[0]], JSON.parse(localStorage.getItem( keys[k]))    ]

            // Add all data to one giant array and return this data
            values.push(keyListItem);
            k++
    }
    return values;
  }
   
   
   // https://stackoverflow.com/questions/55613438/reactwrite-to-json-file-or-export-download-no-server
   // https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
   // https://www.npmjs.com/package/react-json-to-csv
   // https://react-json-to-csv.coston.io/
   saveDataJSON = () => {
            let data = this.getAllDataFromLocalStorage();
            return data
   }

    render() {
        return (
            <div>
                {/*-------------------------------------------------------------   DESKTOP DESIGN*/}
                <div className="todoMenu row desktop">
                    <div className="col s4">
                        <button onClick={this.sendCommand} id="byalphabetically" title="Sort Alphabetically"><i className="material-icons">sort_by_alpha</i></button>
                        <button onClick={this.sendCommand} id="bypriority" title="Sort By Priority"><i className="material-icons">sort</i></button>
                        <button onClick={this.sendCommand} id="byDueDate" title="Sort By Due Date"><i className="material-icons">timelapse</i></button>
                        <button onClick={this.inputCommand} id="search" title="Search"><i className="material-icons">search</i></button>
                        
                    </div>
                    <div className="col s4">
                        {this.inputUpdate()}
                    </div>
                    <div className="col s4 right-align">
                        <button onClick={this.inputCommand} id="editName" title="Edit List Name"><i className="material-icons">edit</i></button>
                        <button onClick={this.inputCommand} id="newList" title="Create New List"><i className="material-icons">playlist_add</i></button>
                        <button onClick={this.inputCommand} id="switchList" title="Switch List"><i className="material-icons">autorenew</i></button>
                        <CsvDownload  title="Download Your Data" data={this.saveDataJSON()} filename="your-data.csv"><i className="material-icons">file_download</i></CsvDownload>
                        {/*
                        <button onClick={this.sendCommand} id="download" title="Download Your Data"></button>
                        <button onClick={this.sendCommand} id="upload" className="btn disabled"><i className="material-icons">file_upload</i></button>
                        <button onClick={this.sendCommand} id="enablecloud" className="btn disabled"><i className="material-icons">cloud_done</i></button>
                        <button onClick={this.test} id="share" className=""><i className="material-icons">folder_shared</i></button> */}
                    </div> 
                </div>

                {/*-------------------------------------------------------------   MOBILE DESIGN*/}
                <div className="todoMenu mobile">
                    <div className="center row col s12">
                    {this.inputUpdate()}
                    </div>
                    <div className="row col s12 center">
                        <button onClick={this.sendCommand} id="byalphabetically" title="Sort Alphabetically"><i className="material-icons">sort_by_alpha</i></button>
                        <button onClick={this.sendCommand} id="bypriority" title="Sort By Priority"><i className="material-icons">sort</i></button>
                        <button onClick={this.sendCommand} id="byDueDate" title="Sort By Due Date"><i className="material-icons">timelapse</i></button>
                        <button onClick={this.inputCommand} id="search" title="Search"><i className="material-icons">search</i></button>
                        <button onClick={this.inputCommand} id="editName" title="Edit List Name"><i className="material-icons">edit</i></button>
                        <button onClick={this.inputCommand} id="newList" title="New List"><i className="material-icons">playlist_add</i></button>
                        <button onClick={this.inputCommand} id="switchList" title="Switch List"><i className="material-icons">autorenew</i></button>
                    </div>
                </div>

            </div>
        )
    }
}
