import React from 'react'

// With a functional component a return acts as the render method
export default function Footer(props) {
    return (
        <footer style={footerStyle}>
            <p style={textStyle}>{props.children}</p>
        </footer>
    )
}

const footerStyle = {
    background: '#152750',
    color:'#fff',
    textAlign: 'center',
    marginTop:40
}

const textStyle = {
    fontSize:'12px',
    margin:0,
    padding:10
}