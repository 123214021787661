import React, { Component } from 'react';
import Header from '../components/layout/Header';
import './WeatherApp/styles.css';

export default class WeatherApp extends Component {
/* FUNCTIONAL COMPOENTS WITH STATE
https://reactjs.org/docs/hooks-state.html

React 16.8. Use State Hook - Allows you to create a state in a functional component
A hook let's you use state and other React features without writing a class.

import React, { useState} from 'react';

// Declaring a State Variable. Add above the return statement
// Earch state variable needs a set variable
// Normally, variables “disappear” when the function exits but state variables are preserved by React.

const [query, setQuery] = useState('');
const [weather, setWeather] = useState({});
const [count, setCount] = useState(0);

// Reading the state variable 
{query}
{weather}
{count}

IN A CLASS
{this.state.count}

// Updating the state
setCount(count + 1)
setWeather(result)
setQuery(e.target.value)

IN A CLASS
this.setState({ count: this.state.count + 1 })
*/

/* ----------------------------------------------------------------------------------
OPEN WEATHER API

Get access to open weather API
www.OpenWeatherMap.org/api

Create an account and get an API key

Go get some images
Create a folder in src called assets

// --------------------- API CALLS
// 5 DAY FORCAST
api.openweathermap.org/data/2.5/forecast?q={city name}&appid={your api key}
http://api.openweathermap.org/data/2.5/forecast?q=Vancouver&appid=e8f2171a3533d162c04ca37bf46c4d8d


Documentation
https://openweathermap.org/forecast5

// CURRENT WEATHER INFO
api.openweathermap.org/data/2.5/weather?q={city name}&appid={your api key}

Documentation
https://openweathermap.org/current
http://api.openweathermap.org/data/2.5/weather?q=Vancouver&units=metric&APPID=e8f2171a3533d162c04ca37bf46c4d8d
*/

    api = {
        key: "e8f2171a3533d162c04ca37bf46c4d8d",
        base: "https://api.openweathermap.org/data/2.5/"
    }

    state = {
        weather: '',
        query: '',
        weatherBackground: 'https://source.unsplash.com/1920x1080/?weather'
    }
    

    search = (e) => {
        // If enter is pressed
        if (e.key === "Enter") {
            // Fetch API
            // api.openweathermap.org/data/2.5/weather?q=Vancouver&appid=e8f2171a3533d162c04ca37bf46c4d8d
            fetch(`${this.api.base}weather?q=${this.state.query}&units=metric&APPID=${this.api.key}`)
            // Create Promise
            .then(res => res.json())
            .then(result => {
                this.setState({ 
                    weather: result,
                    query: '',
                    weatherBackground: `https://source.unsplash.com/1920x1080/?${result.name},${result.weather[0].main}`
                });
                console.log(result);
                document.getElementById('noData').innerText = '';
            }).catch(function() {
                    document.getElementById('noData').innerText = 'Sorry that place must have skipped our records';
                    
            });
        }
    }



    // Stores seach results in the state.
    onChange = (e) => this.setState({
        query: e.target.value
    });



    // ----------------------------------------------------------------------------------
    // Function that creates a readable date data we can use in our app
    dateBuilder = (d) => {
        // Array of months to match up with date function below
        let months = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        // Array of days to match up with date function below
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

        // Get today's date values
        let day = days[d.getDay()];        // # from 0-6 map to array
        let date = d.getDate();            // Get day of the month
        let month = months[d.getMonth()];   // # from 0-11 map to array
        let year = d.getFullYear();        // Get Year

        return `${day} ${month} ${date} ${year}`
    }


    render() {
        // Destructure State
        const { weather, query, weatherBackground } = this.state;

        return (
            <div className="weatherApp" style={{backgroundImage:`url('${weatherBackground}')`}}>
                <main>
                <Header title="Weather App"/>
                    <div className="search-box container">
                        <div className="input-field">
                        <i className="material-icons prefix">search</i> 
                            <input 
                                type="text" 
                                className="search-bar"
                                placeholder="Search for your city..."
                                onChange={this.onChange}
                                value={this.state.query}
                                onKeyPress={this.search}/>
                            </div>
                    </div>

                    {/* If data exists */}
                    {(typeof weather.main != "undefined") ? (
                        
                        <div className="weather-data">
                            <p id="noData"></p>
                            <div className="Country">
                                {weather.name}, {weather.sys.country} <br/>
                            </div>
                            <div className="Date">
                                {  this.dateBuilder(new Date())  } <br/>
                            </div>
                            <div className="main-temp">
                                {Math.round(weather.main.temp)}°C <br/>
                            </div>
                            <div className="temp">
                                <div className="weather-type">
                                    <img src={`http://openweathermap.org/img/w/${weather.weather[0].icon}.png`}/><br/>
                                    {weather.weather[0].main} <br/>
                                </div>
                                {Math.round(weather.main.temp_min)}°C - {Math.round(weather.main.temp_max)}°C <br/>
                                {weather.main.humidity}% Humidity<br/>
                            </div>
                        </div>
                    ) : (  
                        <p id="noData">No Data!</p>
                    )}

                </main>
            </div>
        )
    }
}
