import React, { Component } from 'react'
import Header from '../components/layout/Header';
import About from './About';
import ProjectBox from '../components/layout/ProjectBox';

// Images
import webDesignPanel from '../images/webdesign-panel.jpg'
import todoPanel from '../images/todolist_panel.jpg'
import weatherPanel from '../images/weather_panel.jpg'
import galleryPanel from '../images/gallery_panel.jpg'
import pongPanel from '../images/pong_panel.jpg'
import asteroidPanel from '../images/asteroid_panel.jpg'
import contactusPanel from '../images/contactus_panel.jpg'
import javascriptbookPanel from '../images/javascriptbook_panel.jpg'

// Deploying React App On C-Panel Hosting
// https://medium.com/@aforamitrai/how-to-deploy-react-app-on-shared-hosting-cpanel-d682b0342424

export default class Home extends Component {

    render() {
        return (
            <div className="homepage">

                <Header title="Canadian React / JavaScript Web Developer"/>
                
                <main className="container">
                    <About center="true"> 
                    This is a little website I built with React Js to show what I can do with JavaScript & React. <br/>
                    <b>Take a look at my web development portfolio below.</b>
                    </About>

                    <div className="row">
                        <div className="col s12 l6">
                            <ProjectBox link="/todos" title="Task Master" img={todoPanel} react="true">
                                Task Master is a simple todo list application that stores data in local storage.
                                Comes with a date selector, 
                                Mark certain tasks as important 
                                Sort by priority or due date
                                Add detailed notes to each todo item
                                Create multiple todo lists for each project
                            </ProjectBox>
                        </div>
                        <div className="col s12 l6">
                            <ProjectBox link="/weather-app" title="Weather App" img={weatherPanel} react="true" api="true" axois='true'>
                                Fetches data from the Open Weather Map API to display the 5 day forcast for any city the user chooses.
                                The backgrounds will automatically change based on the search results. Pulls images from Unspalash API.
                            </ProjectBox>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 l6">
                            <ProjectBox link="https://www.greenmarketing.ca/asteroids-game-javascript/" title="Asteroids" img={asteroidPanel} javascript="true">
                                Asteroid game I made with Vaniliha Javascript. Complete with powerups, scoring system, shield and menu.
                            </ProjectBox>
                        </div>
                        <div className="col s12 l6">
                            <ProjectBox link="https://www.greenmarketing.ca/pong-javascript-game/" title="Pong" img={pongPanel} javascript="true">
                                Pong game I made with Vaniliha Javascript. 1 and 2 player modes.
                            </ProjectBox>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12 l6">
                            <ProjectBox link="/gallery" title="Dynamic Gallery" img={galleryPanel} react="true">
                                Dynamic Gallery made with React and Pixel Bay Api
                            </ProjectBox>
                        </div>
                        
                        <div className="col s12 l6">
                                <ProjectBox link="https://docs.google.com/document/d/1uCdI6VdhRZCZKwbVXl-1Nq2jH8tA-3o2A7ub6EOOm4Y/" title="JavaScript Book" img={javascriptbookPanel} react="true" jquery="true" css="true" photoshop="true" illustrator="true" api='true' axois='true'>
                                    
                                </ProjectBox>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col s12 l6">
                            <ProjectBox link="https://www.greenmarketing.ca/web-design-vancouver/" title="Web Design Portfolio" img={webDesignPanel} css="true" jQuery="true" photoshop="true" wordpress="true" illustrator="true" woocommerce='true' php='true'>
                                Web Designs for various businesses
                            </ProjectBox>
                        </div>
                        
                        <div className="col s12 l6">
                            <ProjectBox link="/Contact" title="Contact Form" img={contactusPanel} react='true' php="true" axois='true'>
                                Working contact form made with react and Php backend
                            </ProjectBox>
                        </div>
                    </div>

                </main>
            </div>
        )
    }
}
