import React from 'react'

export default function About(props) {

    let aboutStyle = () => {
        return {
            fontSize:'1.4em',
            textAlign: props.center ? 'center' : 'left'
        }
    }


    return (
        <div className="aboutSection" style={aboutStyle()}>
            <p>
             {props.children}
            </p>   
        </div>
    )
}
