import React from 'react'
import { withRouter } from 'react-router-dom'

const imageStyle = {
    width: '100%',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
}




export default function ImageCard({image, openPopup}) {

    const cardStyle = {
        borderRadius: '5px',
        margin:'10px',
        boxSizing: 'border-box',
        height:'400px',
        backgroundImage:`url(${image.urls.regular})`,
        backgroundSize: 'cover',
        cursor: 'pointer'
    }

    const dataStyle = {
        padding:'10px', 
        textAlign:'center',
        position: 'absolute',
        bottom: '0px',
        width: '100%', 
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        backgroundColor:'rgba(255, 255, 255, 0.8)'
    }

    const sendPhotoId = (e) => {
        // Calls a function in parent and sends data up the chain
        openPopup(e);
        // alert("Open Popup Image: "+e.target.id)
    }

    
    return (
        <div className="col s6">
            <div id={image.id} className="card"  style={cardStyle} onClick={sendPhotoId}>
                {/* <img src={image.webformatURL} alt="" style={imageStyle}/> */}
                <div className="gallery-user">
                    <i className="material-icons">person_pin</i> <a href={`${image.user.links.self}`} target="_blank">{image.user.username}</a> &nbsp;
                </div>
                <p style={dataStyle}>
                {/*<i className="material-icons">remove_red_eye</i>{image.views} &nbsp;*/}
                <i className="material-icons">favorite</i> {image.likes} &nbsp;
                </p>
            </div>
        </div>
    )
}

